const WhatsappWidget = () => {
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
            var s = document.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://media.wanotifier.com/assets/whatsapp-button.js';

              s.onload = function() {
                // @ts-ignore
                CreateWhatsappChatWidget({"phoneNumber":"+918655974394","greetingMessage":"Hi, please share Kenko Health Plan","buttonStyle":"btn-style-2","customImageUrl":""});
              };

              document.body.appendChild(s);
          `,
      }}
    ></script>
  );
};

export default WhatsappWidget;
